<script setup lang="ts">
import { THEME_COLOR } from "~/constants";

export interface SocialLinks {
  facebookUrl?: string;
  xUrl?: string;
  instagramUrl?: string;
  webUrl?: string;
  mailUrl?: string;
  googlePlayUrl?: string;
  appStoreUrl?: string;
  youtubeUrl?: string;
  tikTokUrl?: string;
}

export interface BaseFooterProps {
  /**
   * An array of the footer links that placed next to the logo.
   */
  links?: FooterLink[];
  /**
   * An object of social media links in footer.
   */
  socialLinks?: SocialLinks;
  /**
   * URL to the footer logo. Height is fixed to 56px, width auto adjusted.
   */
  logoImageUrl?: string;
  /**
   * Shows only line with rights text in the footer.
   */
  rightsOnly?: boolean;
  /**
   * The name of the customer to be shown in the rights text of footer.
   */
  customerName?: string;
  isThemeSwitcherShown?: boolean;
  footerText?: string;
}

const { t } = useI18n();
const color = useColorMode();
const props = withDefaults(defineProps<BaseFooterProps>(), {
  customerName: "Pixellot",
  logoImageUrl: "/images/mock/logo.svg",
  isThemeSwitcherShown: true,
});
const links = computed(() => props.links?.map((link) => {
  const i18nKey = link.type === "custom" ? t("footer_links.custom", { custom: link.title }) : t(`footer_links.${link.type}`);
  return {
    url: link.url,
    title: link.title,
    i18nKey,
  };
}));
const currentYear = computed(() => new Date().getFullYear());
const activeTheme = computed(() => (props.isThemeSwitcherShown ? color.value === THEME_COLOR.DARK ? THEME_COLOR.LIGHT : THEME_COLOR.DARK : THEME_COLOR.LIGHT));
</script>

<template>
  <footer class="baseFooter">
    <div v-if="!props.rightsOnly" class="flex flex-wrap items-center justify-center py-10 md:flex-nowrap md:justify-between">
      <div class="flex items-center font-semibold mb-4 md:mb-0">
        <BaseImage
          class="baseFooter__logo"
          :src="props.logoImageUrl"
          fallback-src="/images/mock/logo.svg"
          :alt="`${props.customerName} logo`"
          width="auto"
          :height="56"
        />

        <div v-if="links?.length" class="flex flex-wrap items-center">
          <BaseButton
            v-for="link in links"
            :key="link.url"
            class="text-sm md:text-base mx-2 sm:mx-3 lg:mx-4"
            :tag="link.url.includes('http') ? 'a' : 'nuxt-link'"
            :to="link.url.includes('http') ? {} : link.url"
            :href="link.url.includes('http') ? link.url : undefined"
            type="secondary"
            :name="link.title"
            link
          >
            {{ link.i18nKey }}
          </BaseButton>
        </div>
      </div>
      <div class="flex gap-8 flex-wrap items-center justify-center text-main">
        <BaseSocialIcons
          :facebook-url="props.socialLinks?.facebookUrl"
          :x-url="props.socialLinks?.xUrl"
          :instagram-url="props.socialLinks?.instagramUrl"
          :web-url="props.socialLinks?.webUrl"
          :mail-url="props.socialLinks?.mailUrl"
          :app-store-url="props.socialLinks?.appStoreUrl"
          :google-play-url="props.socialLinks?.googlePlayUrl"
          :youtube-url="props.socialLinks?.youtubeUrl"
          :tik-tok-url="props.socialLinks?.tikTokUrl"
        />
        <BaseThemeSwitcher v-if="props.isThemeSwitcherShown" />
      </div>
    </div>

    <div class="border-b border-[var(--base-footer-line-color)]" />

    <div class="baseFooter__rights">
      <span> © {{ currentYear }} {{ props.customerName }}. {{ t('baseFooter.rights') }}</span>

      <div class="baseFooter__logo">
        <span class="flex-shrink-0 text-main">{{ t('labels.powered_by') }}</span>
        <img class="mx-2 h-2.5" :src="`/images/base/pixellot-logo-${activeTheme}.png`" height="10" width="64" alt="Pixellot-logo">
      </div>
    </div>

    <div v-if="props.footerText" class="text-xs pb-6 text-[var(--base-secondary-color)]" v-html="props.footerText" />
  </footer>
</template>

<style lang="scss">
.baseFooter {
  @apply w-full px-4 md:px-10 xl:px-12;

  background-color: var(--base-footer-background-color);

  .baseFooter__rights {
    @apply flex items-center py-6 gap-6
    md:justify-between
    sm:flex flex-wrap justify-center;
    font-size: 11px;
    font-weight: 400;
    line-height: 13px;
    color: var(--base-secondary-color);

    .baseFooter__logo {
      @apply italic font-semibold
      text-xs text-white flex items-center;
    }
  }

  .baseBtn.baseBtn-link {
    color: var(--base-text-color);
  }

  .router-link-active {
    color: var(--base-text-color) !important;
  }
}

.baseFooter__logo {
  //TODO: change style
  @apply max-h-14 w-auto flex-shrink-0;
}
</style>
